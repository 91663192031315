import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton, Checkbox } from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
  Done,
  Close,
} from '@material-ui/icons';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';
import bookings from './Bookings';
// import Share from './Share/index';
import {
  disableInventoryBooking,
  enableInventoryBooking,
  getInventoryShareLink,
} from '@/actions';
import { useNotify } from 'react-admin';
import Bookings from './Bookings';

export default ({
  DATA = [],
  Del,
  Edit,
  getExportInventoryList,
  getExportInventoryListNotLoading,
  currentPage,
}) => {
  const cls = styles();
  const [showBookingsModal, setShowBookingsModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [refreshInventories, setRefreshInventories] = useState(false);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });
  let uniqueIndex = 0;
  console.log('DATA', DATA);

  const notify = useNotify();

  const getBookingNumbers = (exportList, target) => {
    if (exportList) {
      let activeBookings = [];
      let deactiveBookings = [];
      exportList.bookings.forEach((booking) => {
        if (booking.status === 1) {
          activeBookings.push(booking);
        } else if (booking.status === 0) {
          deactiveBookings.push(booking);
        }
      });

      if (deactiveBookings.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeBookings.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };
  const formatUpdatedAtDate = (dateString) => {
    function isInvalidDate(dateStr) {
      return isNaN(Date.parse(dateStr));
    }

    if (dateString == 'please contact your line') {
      return dateString;
    } else if (dateString == 'TBA') {
      return dateString;
    } else if (isInvalidDate(dateString) == true) {
      return '';
    } else {
      const date = new Date(dateString);
      const formattedDate =
        new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      // console.log(dateString);
      return formattedDate;
    }
  };

  const handleEnableCongtainer = (containerId, index) => {
    enableInventoryBooking(containerId).then((response) => {
      if (response.success) {
        notify('Booking updated');
        // updateContainer(index, 'status', 1);
        // setRefreshInventories(true);
        getExportInventoryListNotLoading();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleDisableContainer = (containerId, index) => {
    disableInventoryBooking(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        // updateContainers(index, 'status', 0);
        // setRefreshInventories(true);
        getExportInventoryListNotLoading();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleChangeContainerStatus = (value, containerId, index) => {
    if (value === true) {
      handleEnableCongtainer(containerId, index);
    } else {
      handleDisableContainer(containerId, index);
    }
  };

  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getBookingNumbers, getExportInventoryList]);

  return (
    <div className={cls.listContainer}>
      {showBookingsModal && selectedRow && (
        <Bookings
          open={showBookingsModal}
          DATA={DATA}
          selectedRow={selectedRow}
          setShowBookingsModal={setShowBookingsModal}
          getExportInventoryList={getExportInventoryList}
        />
      )}
      <table className={cls.list}>
        <thead className={cls.thead}>
          <th style={{ flex: 0.35 }}>#</th>
          <th style={{ flex: 0.8 }}>Size</th>
          <th style={{ flex: 0.8 }}>SSL</th>
          <th style={{ flex: 0.8 }}>Booking#</th>
          <th style={{ flex: 0.6 }}>Number</th>
          <th style={{ flex: 1 }}>Location</th>
          <th style={{ flex: 0.8 }}>ERD</th>
          <th style={{ flex: 0.8 }}>Cutoff</th>
          <th style={{ flex: 0.8 }}>Terminal Apt.</th>
          <th style={{ flex: 0.8 }}>MTO</th>
          <th style={{ flex: 0.8 }}>Available Date</th>
          {/* <th style={{ flex: 0.7 }}>Own Chassis</th>
          <th style={{ flex: 0.7 }}>Pool Chassis</th> */}
          <th style={{ flex: 0.8 }}>Last Update</th>
          <th style={{ flex: 1.1 }}>Action</th>
        </thead>
        <tbody className={cls.tbody}>
          {DATA.map((i, idx) =>
            i.bookings?.length === 0 ? (
              <tr className={cls.tableRow} key={idx}>
                <td style={{ flex: 0.35 }}>
                  {idx + 1 + (currentPage - 1) * 10}
                </td>
                <td style={{ flex: 0.8 }}>{i.size}</td>
                <td style={{ flex: 0.8 }}>{i.ssl}</td>
                <td style={{ flex: 0.8 }}>-</td>
                <td style={{ flex: 0.6 }}>-</td>
                <td style={{ flex: 1 }}>{i.loc}</td>
                <td style={{ flex: 0.8 }}>-</td>
                <td style={{ flex: 0.8 }}>-</td>
                <td style={{ flex: 0.8 }}>-</td>
                <td style={{ flex: 0.8 }}>{fDate(i.date)}</td>
                {/* <td style={{ flex: 0.7 }}>
                  {i.own_chases_count ? <Done /> : <Close />}
                </td>
                <td style={{ flex: 0.7 }}>
                  {i.pool_chases_count ? <Done /> : <Close />}
                </td> */}
                <td style={{ flex: 0.8 }}>{fTimeDate(i.up)}</td>
                <td className={cls.tabActions} style={{ flex: 1.1 }}>
                  <Tooltip arrow title="Edit" placement="top">
                    <IconButton aria-label="edit" onClick={() => Edit(i)}>
                      <CreateRounded className={cls.editIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Bookings" placement="top">
                    <IconButton
                      onClick={() => {
                        setShowBookingsModal(!showBookingsModal);
                        setSelectedRow(i);
                      }}>
                      {/* <img src={getActivBookings(i)} /> */}
                      {getBookingNumbers(i)}
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow title="Delete" placement="top">
                    <IconButton aria-label="delete" onClick={() => Del(i.id)}>
                      <DeleteRounded className={cls.delIcon} />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ) : (
              i.bookings.map((booking, idd) => {
                uniqueIndex++;

                return (
                  <tr className={cls.tableRow} key={idx}>
                    <td style={{ flex: 0.35 }}>
                      {idx + 1 + (currentPage - 1) * 10}
                    </td>
                    <td style={{ flex: 0.8 }}>{i.size}</td>
                    <td style={{ flex: 0.8 }}>{i.ssl}</td>
                    <td style={{ flex: 0.8 }}>
                      {booking.name ? booking.name : 'N/A'}
                    </td>
                    <td style={{ flex: 0.6 }}>
                      {booking.count ? booking.count : 'N/A'}
                    </td>
                    <td style={{ flex: 1 }}>{i.loc}</td>
                    <td style={{ flex: 0.8 }}>
                      {booking?.additional?.erd
                        ? formatUpdatedAtDate(booking?.additional?.erd)
                        : 'N/A'}
                    </td>
                    <td style={{ flex: 0.8 }}>
                      {booking?.additional?.cutoff
                        ? formatUpdatedAtDate(booking?.additional?.cutoff)
                        : 'N/A'}
                    </td>
                    <td style={{ flex: 0.8 }}>
                      {booking?.additional?.appointment_date
                        ? formatUpdatedAtDate(
                            booking?.additional?.appointment_date
                          )
                        : 'N/A'}
                    </td>
                    <td style={{ flex: 0.8 }}>
                      {booking.mto ? booking.mto?.slug : 'N/A'}
                    </td>
                    <td style={{ flex: 0.8 }}>{fDate(i.date)}</td>
                    {/* <td style={{ flex: 0.7 }}>
                  {i.own_chases_count ? <Done /> : <Close />}
                </td>
                <td style={{ flex: 0.7 }}>
                  {i.pool_chases_count ? <Done /> : <Close />}
                </td> */}
                    <td style={{ flex: 0.8 }}>{fTimeDate(i.up)}</td>
                    <td className={cls.tabActions} style={{ flex: 1.1 }}>
                      <Tooltip arrow title="Edit" placement="top">
                        <IconButton aria-label="edit" onClick={() => Edit(i)}>
                          <CreateRounded className={cls.editIcon} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Bookings" placement="top">
                        <IconButton>
                          <Checkbox
                            checked={booking.status === 1 ? true : false}
                            className={cls.checkbox}
                            onChange={(e) =>
                              handleChangeContainerStatus(
                                e.target.checked,
                                booking.id,
                                idd
                              )
                            }
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip arrow title="Delete" placement="top">
                        <IconButton
                          aria-label="delete"
                          onClick={() => Del(i.id)}>
                          <DeleteRounded className={cls.delIcon} />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            )
          )}
        </tbody>
      </table>
    </div>
  );
};
